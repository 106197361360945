<template>
  <section class="invoice-preview-wrapper">
    <b-alert
      variant="danger"
      :show="$router.currentRoute.params.id === undefined"
    >
      <h4 class="alert-heading">Error fetching summary data</h4>
      <div class="alert-body">
        <b-link class="alert-link">
          Halaman ini tidak bisa di refresh atau dibuka melalui direct link atau
          kembali,
        </b-link>
        halaman bersifat one time access atau hanya dapat di akses melalui
        button pada kolom <strong>Details</strong> summary.
      </div>
    </b-alert>
    <div v-if="yo_isLoading" class="progress-wrapper">
      <b-card-text class="mb-0">
        Memuat data… {{ yo_isLoadingValue + '%' }}
      </b-card-text>
      <b-progress
        :key="yo_isLoadingValue"
        animated
        :value="yo_isLoadingValue"
        variant="info"
        max="100"
        :class="'progress-bar-info'"
      />
    </div>
    <b-row
      v-if="invoiceData && $router.currentRoute.params.id"
      class="invoice-preview"
    >
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12" xl="9" md="8">
        <b-card no-body class="invoice-preview-card">
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper">
                  <!-- <logo /> -->
                  <h3 class="text-primary invoice-logo">
                    Tug Boat - Pelayanan Kapal
                  </h3>
                </div>
                <p class="card-text mb-25">PT. Sarana Abadi Lestari</p>
                <p class="card-text mb-25">Kec. Palaran, Kota Samarinda</p>
                <p class="card-text mb-25">
                  +1 (123) 456 7891, +44 (876) 543 2198
                </p>
                <p class="card-text mb-0">Kalimantan Timur 75261</p>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4 class="invoice-title">
                  Summary
                  <span class="invoice-number">#{{ form?.id }}</span>
                </h4>
                <table class="mt-2 mt-xl-0 w-100">
                  <tr>
                    <th class="pb-50">
                      <feather-icon icon="CalendarIcon" class="mr-75" />
                      <span class="font-weight-bold">
                        {{ $t('Date of Application') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ formatDateTime(form?.created_at) }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <feather-icon icon="AnchorIcon" class="mr-75" />
                      <span class="font-weight-bold">
                        {{ $t('Date of Departure') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ formatDateTime(form?.tug_sandar_end_time) || '-' }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <feather-icon icon="AnchorIcon" class="mr-75" />
                      <span class="font-weight-bold"> Tanggal Sandar </span>
                    </th>
                    <td class="pb-50">
                      {{ formatDateTime(form?.tug_sandar_start_time) || '-' }}
                    </td>
                  </tr>

                  <tr>
                    <th class="pb-50">
                      <feather-icon icon="BookOpenIcon" class="mr-75" />
                      <span class="font-weight-bold">
                        {{ $t('Activity') }}
                      </span>
                    </th>
                    <td class="pb-50 text-capitalize">{{ form?.kegiatan }}</td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <feather-icon icon="FlagIcon" class="mr-75" />
                      <span class="font-weight-bold">
                        {{ $t('Form Status') }}
                      </span>
                    </th>
                    <td class="pb-50 text-capitalize">
                      <b-badge :variant="formStatusVariant(form?.form_status)">
                        {{ form?.form_status }}
                      </b-badge>
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <feather-icon icon="UserIcon" class="mr-75" />
                      <span class="font-weight-bold">
                        {{ $t('Pemilik') }}
                      </span>
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{ form.pemilik }}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </b-card-body>
          <!-- Spacer -->

          <!-- KAPAL NON BARGE -->
          <b-card-body class="invoice-padding pb-0">
            <div class="divider my-2">
              <div class="divider-text">
                <b-badge :variant="'info'">
                  <feather-icon icon="AnchorIcon" />
                  KAPAL
                  <feather-icon icon="AnchorIcon" />
                </b-badge>
              </div>
            </div>
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <div class="col col-md-8 p-0">
                <table class="mt-2 mt-xl-0 w-100">
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Nama Kapal') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ form?.tug_boat?.name_tug_boat }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Nomor PKK') }}
                      </span>
                    </th>
                    <td
                      class="pb-50"
                      @click="
                        form.pemilik !== 'Hulu Migas'
                          ? copyText(form.tug_boat.no_pkk_tug_boat)
                          : copyText(form.no_pkk_tug_boat)
                      "
                    >
                      {{
                        form.pemilik !== 'Hulu Migas'
                          ? form.tug_boat.no_pkk_tug_boat
                          : form.no_pkk_tug_boat
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('GT') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ form.tug_boat.gt_tug_boat }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('LOA') }}
                      </span>
                    </th>
                    <td class="pb-50">{{ form.tug_boat.loa_tug_boat }} M</td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Captain') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ form.tug_boat.captain_name_tug_boat }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Captain Telp') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ form.tug_boat.captain_telp_tug_boat }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Shipping Agent') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ form.tug_boat.dm_shipping_agent.shipping_name }}
                    </td>
                  </tr>
                  <tr v-if="form.file_storage_bunkers[0]?.total_bunker">
                    <th class="pb-50">
                      <span class="font-weight-bold">
                        {{ $t('Total Bunker') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ formatNumber(form.file_storage_bunkers[0]?.total_bunker) || '-' }}
                      Liter
                    </td>
                  </tr>
                  <tr v-if="form.file_storage_bunkers[0]?.total_bunker">
                    <th class="pb-50">
                      <span class="font-weight-bold">
                        {{ $t('Nomor Ijin Bunker') }}
                      </span>
                    </th>
                    <td
                      class="pb-50"
                      title="click to copy"
                      @click="
                        copyText(form.file_storage_bunkers[0]?.no_ijin_bunker)
                      "
                    >
                      {{ form.file_storage_bunkers[0]?.no_ijin_bunker || '-' }}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </b-card-body>

          <!-- Attachments -->
          <b-card-body class="invoice-padding pb-0">
            <div class="divider my-2">
              <div class="divider-text">
                <b-badge :variant="'info'">
                  <feather-icon icon="HardDriveIcon" />
                  Document
                  <feather-icon icon="HardDriveIcon" />
                </b-badge>
                <br />
                <strong>[ click name file, to preview.]</strong>
              </div>
            </div>
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <app-timeline-item
                v-for="(item, index) in isHaveFile"
                :key="index"
              >
                <div
                  class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
                ></div>
                <p>
                  <b-img
                    :src="icons(item)"
                    height="auto"
                    width="20"
                    class="mr-1"
                  />
                  <span class="align-bottom">
                    <a
                      :href="item.download_link"
                      target="_blank"
                      style="color: grey"
                      rel="noopener noreferrer"
                      >{{ item.original_name }}</a
                    >
                  </span>
                </p>
                <small class="text-muted">Size {{ item.size }} </small>
              </app-timeline-item>
            </div>
          </b-card-body>

          <!-- Jetty Man -->
          <b-card-body class="invoice-padding pb-0">
            <div class="divider my-2">
              <div class="divider-text">
                <b-badge :variant="'info'">
                  <feather-icon icon="MapIcon" />
                  Jetty Man
                  <feather-icon icon="MapIcon" />
                </b-badge>
                <br />
                [ Tujuan Kapal : {{ form?.tujuan_kapal_tb }} ]
              </div>
            </div>
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
              v-if="form.pemilik !== 'Hulu Migas'"
            >
              <div class="col col-md-5 p-0">
                <table class="mt-2 mt-xl-0 w-100">
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Posisi Jetty') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ form.position_jetty || '-' }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Posisi Baris Jetty') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ form.baris_position_jetty || '-' }}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <b-card-body>
              <div
                class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
              >
                <!-- Tug Boat -->
                <div class="p-0">
                  <!-- <h6 class="mb-2">Main Boat:</h6> -->
                  <div class="divider my-2">
                    <div class="divider-text">
                      <h5>{{ form.tug_boat.name_tug_boat }}</h5>
                      <span
                        style="
                          @media (min-width: 390px) and (max-width: 768px) {
                            display: block;
                            word-wrap: break-word;
                            width: 50px;
                            white-space: normal;
                          }
                        "
                        >{{ this.form_realisasi.rka2 }}</span
                      >
                      <br />
                      <strong><span>Sandar → Berangkat</span></strong>
                    </div>
                  </div>
                </div>
              </div>
              <b-tabs
                class="tabs-custom"
                content-class=""
                align="center"
                v-if="form.pemilik === 'Hulu Migas'"
              >
                <b-tab lazy>
                  <template #title>
                    <feather-icon icon="GitMergeIcon" size="25" />
                    <strong>
                      <span style="font-size: 1.5em">
                        {{ form.tug_boat.name_tug_boat }}
                      </span>
                    </strong>
                  </template>
                  <!-- content -->
                  <div
                    class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
                  >
                    <NestedIO
                      class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0 p-0"
                      uri="realisasi_posisi_kapal"
                      :key="form.table_jetty_position"
                      :model-id="form.id"
                      model="form_tug_boat"
                      :posisi="jetty_positions"
                      :posisi-baris="jetty_baris_positions"
                      :mode-summary="true"
                      :payload="{
                        model_id: null,
                        model: null,
                        is_first: null,
                        posisi_awal: null,
                        posisi_awal_baris: null,
                        posisi_skrg: null,
                        posisi_skrg_baris: null,
                        reason: null,
                        waktu_pindah: null
                      }"
                      :column-repeat="[
                        {
                          fe_id: 1
                        }
                      ]"
                      :d-t-o="[]"
                      :resolve-fetch="
                        (response) => response.data.realisasi_posisi_kapals
                      "
                      buttonOkTitle=""
                    />
                  </div>
                </b-tab>
                <b-tab lazy>
                  <template #title>
                    <feather-icon icon="GitMergeIcon" size="25" />
                    <strong>
                      <span style="font-size: 1.5em">Boat Timeline </span>
                    </strong>
                  </template>
                  <ChainTimeline barge-or-tug="tugboat" :data-i-d="form.id" />
                </b-tab>
              </b-tabs>
            </b-card-body>
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <div class="col col-md-9 p-0">
                <table class="mt-2 mt-xl-0 w-100">
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Waktu Sandar') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ formatDateTime(form.tug_sandar_start_time) || '-' }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Waktu Berangkat') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ formatDateTime(form.tug_sandar_end_time) || '-' }}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </b-card-body>

          <!-- Admin SAL -->
          <b-card-body class="invoice-padding pb-0">
            <div class="divider my-2">
              <div class="divider-text">
                <b-badge :variant="'info'">
                  <feather-icon icon="UnlockIcon" />
                  Admin SAL
                  <feather-icon icon="UnlockIcon" />
                </b-badge>
              </div>
            </div>

            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0 p-0"
            >
              <div class="col col-md-6 p-0">
                <!-- Main Boat -->
                <div class="divider my-2">
                  <div class="divider-text">
                    <!-- <b-badge :variant="'info'">RKA 1</b-badge> -->
                    <h5>{{ form.tug_boat.name_tug_boat }}</h5>
                    <span
                      style="
                        @media (min-width: 390px) and (max-width: 768px) {
                          display: block;
                          word-wrap: break-word;
                          width: 50px;
                          white-space: normal;
                        }
                      "
                      >{{ this?.form_realisasi?.keg_tb }}</span
                    >
                    <br />
                    <strong>
                      <span
                        style="
                          @media (min-width: 390px) and (max-width: 768px) {
                            display: block;
                            word-wrap: break-word;
                            width: 50px;
                            white-space: normal;
                          }
                        "
                        >Mulai Berkegiatan → Selesai Berkegiatan</span
                      >
                    </strong>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0 p-0"
            >
              <!-- Main Boat -->
              <div class="col col-md-6 p-0">
                <table class="mt-2 mt-xl-0 w-100 p-0">
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Nomor RPKRO') }}
                      </span>
                    </th>
                    <td
                      class="pb-50"
                      v-b-tooltip.hover
                      title="click to copy"
                      @click="copyText(form?.no_rpkro_tb)"
                    >
                      {{ form?.no_rpkro_tb || '-' }}
                    </td>
                  </tr>
                  <tr v-if="form.pemilik == 'TBBM'">
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        Nomor PPK
                      </span>
                    </th>
                    <td
                      class="pb-50"
                      v-b-tooltip.hover
                      title="click to copy"
                      @click="copyText(form?.no_ppk_tb)"
                    >
                      {{ form?.no_ppk_tb || '-' }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Disetujui Sandar') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ formatDateTime(form?.disetujui_time_tb) || '-' }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Nomor SPOG') }}
                        {{
                          form.pemilik === 'Hulu Migas' ? '(Kedatangan)' : ''
                        }}
                      </span>
                    </th>
                    <td
                      class="pb-50"
                      v-b-tooltip.hover
                      title="click to copy"
                      @click="copyText(form?.no_spog_tb)"
                    >
                      {{ form?.no_spog_tb || '-' }}
                    </td>
                  </tr>
                  <tr v-if="form.pemilik === 'Hulu Migas'">
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Nomor SPOG') }} (Keberangkatan)
                      </span>
                    </th>
                    <td
                      class="pb-50"
                      v-b-tooltip.hover
                      title="click to copy"
                      @click="copyText(form?.no_spog_tb_brk)"
                    >
                      {{ form?.no_spog_tb_brk || '-' }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Mulai Kegiatan') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ formatDateTime(form?.kegiatan_start_time_tb) || '-' }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <!-- <feather-icon icon="AnchorIcon" class="mr-75" /> -->
                      <span class="font-weight-bold">
                        {{ $t('Selesai Kegiatan') }}
                      </span>
                    </th>
                    <td class="pb-50">
                      {{ formatDateTime(form?.kegiatan_end_time_tb) || '-' }}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </b-card-body>

          <b-card-body>
            <strong class="mr-2 mb-2 text-center">
              <span v-text="`Form Perencanaan Created BY ${form.created_by}`" />
            </strong>
          </b-card-body>
          <br />
          <!-- Spacer -->
          <hr class="invoice-spacing" />

          <!-- Note -->
          <b-card-body class="invoice-padding pt-0">
            <span class="font-weight-bold">Note: </span>
            <span>
              It was a pleasure working with you and your team. We hope you will
              keep us in mind for future shipping. Thank You!
            </span>
          </b-card-body>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
        style="position: fixed; right: 0"
      >
        <b-card style="" class="custom-verification">
          <!-- Button: Send Invoice -->
          <!-- <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-toggle.sidebar-send-invoice
            variant="primary"
            class="mb-75"
            block
            :disabled="true"
          >
            Send Email
          </b-button> -->

          <!-- Button: DOwnload
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
          >
            Download
          </b-button> -->

          <!-- Button: Print -->
          <!-- <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            @click="printInvoice"
          >
            Print
          </b-button> -->

          <!-- Button: Edit -->
          <!-- <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            :to="{
              name: 'apps-invoice-edit',
              params: { id: $route.params.id }
            }"
          >
            Edit
          </b-button> -->

          <!-- Button: Add Payment -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            :to="$router.currentRoute.params.previous_link"
          >
            <!-- :to="{
              name: 'dashboard-monitoring-rrv-tbbm'
            }" -->
            Kembali
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            class="mb-75"
            block
            :to="{
              name: 'dashboard-monitoring-rrv-tug-boat-view',
              params: {
                id: form.id,
                api: 'form_tug_boat',
                previous_link: $router.currentRoute
              }
            }"
          >
            Realisasikan
          </b-button>

          <!-- <b-dropdown id="status">
            <template #button-content>
              <span v-text="form.form_status || 'Tidak ada status'" />
            </template>
            <b-dropdown-header
              v-if="form.form_status !== 'KEGIATAN DIBATALKAN'"
              id="dropdown-header-label"
            >
              <small class="text-secondary">Ubah status ke </small>
            </b-dropdown-header>
            <b-dropdown-item
              v-if="
                form.form_status !== 'KEGIATAN DIBATALKAN' &&
                $store.state.app.roleName !== 'viewer'
              "
              variant="danger"
              @click="onUpdateStatus(true, form.form_status)"
            >
              <b-badge :variant="'danger'"> BATALKAN KEGIATAN </b-badge>
            </b-dropdown-item>
            <b-dropdown-item v-else variant="info">
              <b-badge
                :variant="'info'"
                v-if="$store.state.app.roleName !== 'viewer'"
              >
                Tidak Dapat Mengubah Status yang Telah Dibatalkan.
              </b-badge>
            </b-dropdown-item>
          </b-dropdown> -->
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  VBToggle
} from 'bootstrap-vue'
import Logo from '@core/layouts/components/Logo.vue'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import ChainTimeline from '@views/pages/monitoring/jalur-sungai/hulu-migas/summary/ChainTimeline.vue'
import NestedIO from '@views/pages/components/NestedIO.vue'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle
  },
  components: {
    NestedIO,
    ChainTimeline,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,

    Logo,
    ToastificationContent
  },
  computed: {
    formStatusVariant() {
      return (any) => this.$variantDDFormStatus[any]
    },
    showVerification() {
      return () => {
        if (this.$can('SAL', '')) {
          const cond =
            !this.form.schedule.no_rpkro ||
            !this.form.schedule.no_spog ||
            !this.form.schedule.kegiatan_start_time
          const cond1 =
            !this.form.schedule.no_rpkro_tb ||
            !this.form.schedule.no_spog_tb ||
            !this.form.schedule.kegiatan_start_time_tb

          if (this.form.schedule.jenis_kapal === 'Barge') {
            return cond || cond1
          } else {
            return cond
          }
        }
        if (this.$can('manage', 'special')) {
          return !this.form.schedule.no_do_bl
        }
        if (this.$can('TBBM', '')) {
          const cond =
            !this.form.schedule.no_rkbm || !this.form.schedule.no_pbmbb
          const cond1 =
            !this.form.schedule.no_rkbm_tb || !this.form.schedule.no_pbmbb_tb
          if (this.form.schedule.jenis_kapal === 'Barge') {
            return cond || cond1
          } else {
            return cond
          }
        }
        return true
      }
    }
  },

  setup() {
    const paymentDetails = {
      totalDue: '$12,110.55',
      bankName: 'American Bank',
      country: 'United States',
      iban: 'ETD95476213874685',
      swiftCode: 'BR91905'
    }
    const invoiceDescription = [
      {
        taskTitle: 'Native App Development',
        taskDescription:
          'Developed a full stack native app using React Native, Bootstrap & Python',
        rate: '$60.00',
        hours: '30',
        total: '$1,800.00'
      },
      {
        taskTitle: 'UI Kit Design',
        taskDescription:
          'Designed a UI kit for native app using Sketch, Figma & Adobe XD',
        rate: '$60.00',
        hours: '20',
        total: '$1200.00'
      }
    ]

    const printInvoice = () => {
      window.print()
    }

    return {
      invoiceDescription,
      printInvoice,
      paymentDetails
    }
  },
  data() {
    return {
      BACKEND_URL: process.env.VUE_APP_BACKEND_URL,
      jetty_positions: [],
      jetty_baris_positions: [
        {
          value: 'null',
          text: 'Posisi Baris'
        },
        {
          value: 'Baris 1',
          text: 'Baris 1'
        },
        {
          value: 'Baris 2',
          text: 'Baris 2'
        },
        {
          value: 'Baris 3',
          text: 'Baris 3'
        }
      ],
      yoTimeoutDebounce: null,
      yo_isLoading: false,
      yo_isLoadingValue: 25,
      invoiceData: {
        id: 1,
        issuedDate: '13 Dec 2019',
        client: {
          address: '7777 Mendez Plains',
          company: 'Hall-Robbins PLC',
          companyEmail: 'don85@johnson.com',
          country: 'USA',
          contact: '(616) 865-4180',
          name: 'Jordan Stevenson'
        },
        service: 'Software Development',
        total: 3428,
        avatar: '',
        invoiceStatus: 'Paid',
        balance: '$724',
        dueDate: '23 Apr 2019'
      },
      isHaveFile: null,
      form_realisasi: {
        rka1: null,
        rka2: null,
        keg: null,
        keg_tb: null
      },
      form: null,
      created_at: null,
      updated_at: null
    }
  },
  created() {
    this.fetchData()
  },
  mounted() {
    document.body.style.zoom = '100%'
  },
  destroyed() {
    document.body.style.zoom = '100%'
  },
  methods: {
    moment,
    formatNumber(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
    isTimerDone() {
      clearInterval(this.yoTimer)
      this.yoTimer = null
      this.yo_isLoading = false
    },
    isTimerStart() {
      this.yo_isLoading = true
      this.rows = null
      this.yoTimer = setInterval(() => {
        // eslint-disable-next-line
        if (this.yo_isLoadingValue <= 75 && this.yo_isLoading >= 0) {
          this.yo_isLoadingValue += 25
        } else {
          this.yo_isLoadingValue = 0
        }
      }, 1000)
    },
    icons(item) {
      console.log('item.type', item.type)
      if (['image/png', 'png'].includes(item.type)) {
        return require('@/assets/images/icons/png.png')
      }
      if (['image/jpeg', 'jpeg', 'jpg'].includes(item.type)) {
        return require('@/assets/images/icons/jpg.png')
      }
      if (['application/pdf'].includes(item.type)) {
        return require('@/assets/images/icons/pdf.png')
      }
      if (['xls'].includes(item.type)) {
        return require('@/assets/images/icons/pdf.png')
      }
    },
    copyText(string) {
      this.$copyText(string).then(
        () => {
          this.$alert({
            title: `Text copied : ${string}`,
            variant: 'primary',
            icon: 'CheckCircleIcon'
          })
        },
        (e) => {
          this.$alert({
            title: `Can not copy!`,
            variant: 'danger',
            icon: 'XCircleIcon'
          })
        }
      )
    },
    formatDateTime(value, format = 'D MMMM YYYY - HH:mm') {
      if (!value) {
        return ''
      }
      const data = moment(value).format(format)
      return data
    },
    durationAsString(start, end) {
      const duration = moment.duration(moment(end).diff(moment(start)))

      //Get Days
      const days = Math.floor(duration.asDays()) // .asDays returns float but we are interested in full days only
      const daysFormatted = days ? `${days} days ` : '' // if no full days then do not display it at all

      //Get Hours
      const hours = duration.hours()
      const hoursFormatted = `${hours} hours `

      //Get Minutes
      const minutes = duration.minutes()
      const minutesFormatted = `${minutes} minutes`

      //Get seconds
      var seconds = duration.seconds()
      const secondsFormatted = ` ${seconds} seconds`

      //Get milliseconds
      var milliseconds = moment(end).diff(moment(start))
      const millisecondsFormatted = ` | ${milliseconds} milliseconds`

      return [
        daysFormatted,
        hoursFormatted,
        minutesFormatted,
        secondsFormatted,
        millisecondsFormatted
      ].join('')
    },
    async fetchData() {
      const uriAPI = 'form_tug_boat'
      // const dataID = 1
      const dataID = this.$router.currentRoute.params.id

      if (dataID !== undefined) {
        this.isTimerStart()

        await useJwt.http
          .get(`${uriAPI}/${dataID}`)
          .then((response) => {
            this.isTimerDone()

            const origin = response.data[`${uriAPI}`]
            const { user, file_storages, file_storage_bunkers } =
              response.data[`${uriAPI}`]
            this.updated_at = origin.updated_at
            this.created_at = origin.created_at

            this.form = {
              ...origin,
              form_status: '',
              created_by: `${user.full_name} ( ${user.perusahaan || ''} ${
                !user.perusahaan ? '' : '-'
              } ${user.phone} )`
            }
            let one = this.form.tug_sandar_start_time
            let two = this.form.tug_sandar_end_time

            this.form_realisasi.rka2 = this.durationAsString(
              moment(one),
              moment(two)
            )
            let one_tb = this.form.kegiatan_start_time_tb
            let two_tb = this.form.kegiatan_end_time_tb

            this.form_realisasi.keg_tb = this.durationAsString(
              moment(one_tb),
              moment(two_tb)
            )
            if (origin.is_cancel === true) {
              this.form.form_status = 'KEGIATAN DIBATALKAN'
            } else {
              this.form.form_status = 'KAPAL RENCANA SANDAR'
              if (origin.tug_sandar_start_time) {
                this.form.form_status = 'KAPAL SANDAR'
              }
              if (origin.tug_sandar_end_time) {
                this.form.form_status = 'KAPAL BERANGKAT'
              }
            }

            this.isHaveFile = []
            if (file_storages) {
              file_storages.forEach((item) => {
                this.isHaveFile.push({
                  original_name: `${item.original_name}`,
                  size: `${item.size}`,
                  type: item.type,
                  download_link: `${this.BACKEND_URL}${item.path}/${item.unique_name}`
                })
              })
            }
            if (file_storage_bunkers) {
              file_storage_bunkers.forEach((item) => {
                // this.form.schedule.no_ijin_bunker = item.no_ijin_bunker
                // this.form.schedule.total_bunker = item.total_bunker
                this.isHaveFile.push({
                  original_name: `${item.original_name}`,
                  size: `${item.size}`,
                  type: item.type,
                  download_link: `${this.BACKEND_URL}${item.path}/${item.unique_name}`
                })
              })
            }

            console.log('init payload:', this.form)
          })
          .catch((err) => {
            this.isTimerDone()
            console.log(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Error load data ${err}`,
                icon: 'XIcon',
                variant: 'danger'
              }
            })
          })
          .finally(() => {
            this.isTimerDone()
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-invoice.scss';
.ps-customizer-area {
  height: calc(100% - 83px);
}
.invoice-preview-wrapper {
  .row {
    .invoice-actions {
      .custom-verification {
        &:hover {
          transition: all 0.3s;
          box-shadow: 4px 4px 4px darkblue;
          scale: 1.2;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.invoice-preview-wrapper {
  .invoice-actions {
    position: fixed !important;
    right: 0 !important;
    @media (max-width: 768px) {
      position: inherit !important;
    }
  }
}
@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
